import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
// import Image from "../components/image"
import SEO from "../components/seo";

import "./member-agreement.scss";

// import Button from "../components/button/button"
// import SectionHeading from "../components/section-heading/section-heading"
// import ContactSection from "../components/contact-section/contact-section"
import CappedWidth from "../components/capped-width/capped-width";

//Import images from image.js here:
// import { EllenWreath } from "../components/image"

const MemberAgreementPage = () => (
  <Layout>
    <SEO title="Member Agreement" />

    {/* Why Join OWW */}
    <div className="section member-agreement">
      <CappedWidth>
        <h1 className="page-title">Member Agreement</h1>
        <p>
          The Online Writing Workshop for SF & F is open to all writers of
          science fiction, fantasy, or horror, both aspiring and professional.
          You need to become a member in order to participate. We require a
          valid email address, and because of occasional adult content, people
          under 18 must get parental permission before joining. Pseudonyms may
          be used. Multiple memberships are not allowed.
        </p>
        <p>
          After becoming a member, you may submit your work, review the work of
          others, and participate in workshop discussions. Other members will
          also be able to read, rate, and review the work you submit.
        </p>
        <p>
          Because reviews and ratings are what improve people's writing, we
          require that you contribute reviews in order to be allowed to post
          your own work and get it reviewed. And because we want all members to
          have a good chance of getting their work reviewed, we have a limit on
          how many pieces any member can submit for review at once.
        </p>
        <p>Rules about submitting your work:</p>
        <li>You need four review points to post an active submission. </li>
        <li>
          You're given four review points when you join the workshop, so you can
          post your first submission before contributing any reviews.{" "}
        </li>
        <li>
          After that, you earn one review point for each substantive review you
          post, with a bonus point awarded if that submission has zero reviews.{" "}
        </li>
        <li>Very short reviews are allowed, but don't earn review points. </li>
        <li>
          Once you earn the points, they're yours until you spend them by
          posting a submission. Even if the submission you reviewed is removed
          from the workshop, you still keep your points.{" "}
        </li>
        <li>
          You may post up to three active submissions at a time: three chapters,
          three short stories, etc. Three chapters from one novel equal three
          submissions. (Some browser configurations do not allow submission of
          long pieces all at once, so if your story or chapter is too long for
          the text window, you may use our "append text" feature to add the
          second part. Submit what you can, then go to your partial submission
          and click the "Update this submission" link at the top. Scroll down to
          beneath the submission text box and click the "append form" link to
          add the rest of your submission. No extra points are needed for this.){" "}
        </li>
        <li>
          Editors' Choice submissions are exempted from the
          three-active-submission limit, so you may occasionally see some
          members who have more than three pieces posted.{" "}
        </li>
        <li>
          You may use the Library feature to store submissions in read-only
          format (these are your "shelved" submissions). You can move active
          (reviewable) submissions to your Library shelves and vice versa. It
          only costs points to post an active submission the first time;
          re-posting ("unshelving") it later is free. There is no time limit on
          shelved submissions and they are not removed automatically from the
          workshop as long as you are a member.
        </li>
        <br />
        <p>Rules about the content of your submission: </p>
        <li>It must be written by the person submitting it. </li>
        <li>It must be science fiction, fantasy, or horror. </li>
        <li>
          It must be no longer than 7500 words (about 30 double-spaced pages).{" "}
        </li>
        <li>
          It must not use licensed worlds, universes, or the characters from
          those worlds or universes (including but not limited to those from
          Star Trek®, Star Wars®, and computer or role-playing games).{" "}
        </li>
        <li>
          It may contain "explicit" material--swearing, sex scenes--as long as
          that material is comparable (in extent and explicitness) to that in
          genre novels you can find on the shelves of most bookstores. Use your
          best judgment. If you're in doubt, it's probably too much for the
          genre! (If your submission does contain a lot of "explicit" material,
          mark it as Adult Only and note that in the area for author comments.){" "}
        </li>
        <li>
          You must have read and agreed to our legal disclaimer before
          submitting. This protects you and us.{" "}
        </li>
        <br />
        <p>
          {" "}
          Online Writing Workshops, as owner and administrator of the workshop,
          has these rights regarding submissions:{" "}
        </p>
        <li>
          We may delete a submission at any time for reasons including but not
          limited to inappropriateness for the workshop, obscenity, and
          plagiarism.{" "}
        </li>
        <li>
          We may email the author regarding his/her submissions for various
          reasons.{" "}
        </li>
        <li>
          It must be no longer than 7500 words (about 30 double-spaced pages).{" "}
        </li>
        <li>
          We may select a submission as a monthly "Editors' Choice" and list it
          as such on the Editors' Choice page and in the workshop's newsletter.{" "}
        </li>
        <p>
          But we don't have the right to publish any author's work or submit it
          for publication, nor do we have an option on publishing that work, or
          any sort of claim to it, just because that work or a portion thereof
          has been posted in the workshop. Selecting a submission as an
          "Editors' Choice" does not give us any rights or options. All rights
          remain with the author of the work. In even plainer language, it's
          yours, not ours.{" "}
        </p>
        <br />
        <p>Rules about rating and reviewing: </p>
        <li>You may not rate or review your own submission(s).</li>
        <li>
          Reviews must contain some useful content for the author, not just "I
          liked it" or "I couldn't read this." Very brief reviews are allowed,
          but will not earn review points. Ideally, reviews should contain
          specific good and bad points. All reviews should include at least one
          positive comment.
        </li>

        <li>
          Members who post contentless reviews in order to earn points will have
          those reviews deleted (and points removed) and may have their
          membership revoked if they do not mend their ways. Play fair!
        </li>

        <li>
          The point of the workshop is to help SF/F/H writers improve their
          writing. Therefore, constructive criticism is encouraged; insults and
          put-downs are not allowed. Try to be clear but not harsh in your
          criticisms, and don't forget to compliment the good points. Put
          yourself in the writer's shoes before you slam a submission, no matter
          how poorly written it may be.
        </li>

        <li>
          Flaming, personal attacks, and an aggressive, condescending tone are
          not allowed. Members who cannot follow this rule will be banned from
          the workshop.
        </li>
        <br />
        <p>
          {" "}
          Online Writing Workshops, as owner and administrator of the workshop,
          has these rights regarding reviews:{" "}
        </p>
        <li>
          We may remove an active submission and its reviews from the workshop
          automatically three months after its submission date or last update.
          This is to keep workshop members focused on newer submissions and
          submissions that are being revised. (If your active submission is
          scheduled to be removed, you will receive email notifying you two
          weeks in advance; you may then either update your submission to keep
          it from being removed, or download the reviews and ratings for your
          submission before they are removed with the submission. You can have
          your own submissions and any reviews emailed to you at any time.){" "}
        </li>

        <li>
          We may remove an active submission and its reviews from the workshop
          automatically three months after its submission date or last update.
          This is to keep workshop members focused on newer submissions and
          submissions that are being revised. (If your active submission is
          scheduled to be removed, you will receive email notifying you two
          weeks in advance; you may then either update your submission to keep
          it from being removed, or download the reviews and ratings for your
          submission before they are removed with the submission. You can have
          your own submissions and any reviews emailed to you at any time.){" "}
        </li>

        <li>
          At our discretion, instead of removing active submissions from the
          workshop entirely, we may move them to the member's Library shelves,
          where they are still readable but not reviewable.
        </li>

        <li>
          We may delete a review at any time for reasons including but not
          limited to inappropriateness for the workshop, obscenity, and just
          plain unconstructive meanness. Flames and personal attacks will be
          deleted.
        </li>

        <li>
          We may contact a member about a contentless review, request revisions,
          or direct that member to resources on how to review. We may also
          delete contentless reviews.
        </li>

        <li>
          We may contact a member about an inappropriate review and request
          revisions. We may also cancel a person's membership and ban a person
          from participating in the workshop, with or without prior warning, if
          he or she seems incapable of participating in a constructive manner.
          We will cancel the memberships of reviewers who consistently
          contribute reviews that are aggressive in tone and inappropriate for
          the workshop environment. In those cases we may or may not refund a
          prorated amount of the member's membership fee, at our discretion.
        </li>
        <br />
        <p>Rules about information displayed on this site:</p>
        <li>
          You may not use members' names, email addresses, or other public
          information for any commercial purpose. This includes sending out
          commercial advertising via email to members, creating commercial
          mailing lists from members' email addresses, or any other commercial
          use. Violators of this rule will be permanently banned from the
          workshop. Members who suspect violation of this rule should inform
          Online Writing Workshops by using our contact form.
        </li>
        <li>
          Online Writing Workshops itself has a privacy policy as well. We won't
          give out members' names or email addresses for commercial use, either,
          though we may occasionally send out a message on behalf of our sponsor
          partners about their books of interest to members, not more than 4
          times per year.
        </li>
        <br />
        <p>
          {" "}
          We reserve the right to cancel your membership, with or without prior
          notice, if we learn that you have:{" "}
        </p>
        <li>registered under multiple usernames</li>
        <li>made submissions that do not follow our guidelines </li>
        <li>
          submitted reviews that personally attack or flame other members{" "}
        </li>
        <li>consistently submitted contentless reviews </li>
        <li>used member information for commercial purposes </li>
        <li>or otherwise violated this member agreement. </li>

        <p>
          In these cases we may or may not refund a prorated amount of any
          membership fee paid, at our discretion.
        </p>

        <p>Your membership rights:</p>
        <li>
          You may delete or update your own submissions and reviews at any time,
          and update the information in your member-directory listing, too, as
          long as you continue to provide us with a valid email address.{" "}
        </li>
        <li>
          You may terminate your membership at any time, for any reason you
          wish. Just log in, then go to the Profile section of your Dashboard
          and click on "Delete membership." Your submissions (both active and
          shelved in the Library) will be removed from the workshop as soon as
          you delete your membership. However, your reviews will remain in the
          workshop unless you delete each one before your delete your
          membership.{" "}
        </li>
        <li>
          You may contact us to clarify any workshop rules and rights, complain
          about a review, report a problem with the workshop, or make
          suggestions. We will do our best to reply to you within a day or two.{" "}
        </li>

        <p>
          You may also find our Notice to Writers of interest, especially if you
          have not participated in a workshop before.
        </p>

        <p>
          For more about how the workshop works and what features we offer, see{" "}
          <Link to="/how-oww-works">How it Works</Link>.
        </p>
        <br />
      </CappedWidth>
    </div>
  </Layout>
);

export default MemberAgreementPage;
